module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#00239C","theme_color":"#00239C","display":"minimal-ui","icon":"src/images/mountainpass-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5eb0cc749fcee07d483dabc301050057"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager-delayed/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MN5FHGQ","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
